<template>
  <div style="padding-top:50px;background: #f4f5f7; padding-bottom: 200px; ">
    <div>
      <div style="width: 1170px; margin: 0 auto;">
        <div v-if="!pageLoaded" class="loader" style="text-align: center;"></div>
        <div v-else style="margin-bottom: 100px; ">

          <div v-if="1===2" style="display: flex">
            <div style="width: 300px;">
              <h4>Yazı Rengini Değiştir</h4>
              <div class="hiddendiv10"></div>
              <select @change="changeStoryInfo" class="super-drop" v-model="topic.color">
                <option value="black">Siyah</option>
                <option value="white">Beyaz</option>
              </select>
            </div>
            <div style="width: 300px;">
              <h4>Yazının Posizyonu Değiştir</h4>
              <div class="hiddendiv10"></div>
              <select @change="changeStoryInfo" class="super-drop" v-model="topic.style_type">
                <option value="up_title">Yukarıda</option>
                <option value="bottom_title">Aşağıda</option>
                <option value="bold">Kalın</option>
                <option value="long_title">Uzun ve Altta</option>
              </select>
            </div>
          </div>

          <div class="hiddendiv"></div>

          <h4>Ölçü: 1920 x 780 px (Blog Detay Sayfası)</h4>
          <div class="hiddendiv10"></div>

          <label style="width: 120px!important; cursor: pointer" for="files_application" >
            <span v-if="!image_url_uploading" class="super-button-small"> Görsel Yükle</span>
            <span v-else>Bekleyin...</span>
          </label>

          <input id="files_application" style="display:none;" type="file" @change="onFileChange">

          <div v-if="image_url_uploading">Dosya yükleniyor . . .</div>
          <div v-if="image_url_uploading" class="loader"></div>

          <div style=" margin-top: 40px; margin: 0 auto; text-decoration: none!important;">
            <div style="height: 20px;"></div>
            <div v-if="topic.style_type !== 'list'" :class="['cardA1', 'generalCard', 'card_'+topic.style_type, topic.color]" :style="' background-size:cover; background-image: url('+topic.image_url+');'">
              <div v-if="topic.style_type === 'bottom_title'" class="bottom_text_placeholder">
                <p class="subheader">{{topic.subheader}}</p>
                <p class="title" v-html="topic.header"></p>
                <p class="subheader2">{{topic.subheader2}}</p>
              </div>
              <div v-else-if="topic.style_type !== 'long_title'">
                <p class="subheader">{{topic.subheader}}</p>
                <p class="title" v-html="topic.header"></p>
                <p class="subheader2">{{topic.subheader2}}</p>
              </div>
            </div>
            <div v-if="topic.style_type === 'list'" :class="['generalCard', 'card_'+topic.style_type, topic.color]">
              <p class="subheader">{{topic.subheader}}</p>
              <p class="title">{{topic.header}}</p>
            </div>
          </div>

          <div class="hiddendiv"></div>
          <div class="hiddendiv"></div>
          <div class="hiddendiv"></div>
          <h4 v-if="1===1 || topic.new_today_position === 'big'">Ölçü: 1056 x 600 px (Ana Sayfa Yerleşimleri)</h4>
          <h4 v-else>Ölçü: 482 x 348 px</h4>
          <div class="hiddendiv10"></div>
          <label style="width: 120px!important; cursor: pointer" for="files_application_new_today" >
            <span v-if="!image_url_new_today_uploading" class="super-button-small"> Görsel Yükle</span>
            <span v-else>Bekleyin...</span>
          </label>
          <input id="files_application_new_today" style="display:none;" type="file" @change="onFileChange_new_today">
          <div class="hiddendiv10"></div>
          <div class="hiddendiv10"></div>
          <div  v-if="1===1 || topic.new_today_position === 'big'" :class="topic.style_type === 'long_title' ? 'freelancer-box_long_title' : 'freelancer-box'" :style="'background-image: url('+topic.image_url_new_today+')'">
            <template v-if="topic.style_type === 'long_title'">
              <p :class="'sub-title '+topic.color " :style="'backgroundColor:'+computedBG">{{topic.header}}</p>
              <p :class="'title '+topic.color ">{{topic.subheader}}</p>
              <p :class="'sub-title2 '+topic.color " >{{topic.subheader2}}</p>
              <button v-if="topic.button_text" :class="topic.color === 'black' ? 'red-button' : 'white-button'" >{{topic.button_text}}</button>

            </template>
            <template v-else>
              <p :class="'sub-title '+topic.color ">{{topic.header}}</p>
              <p :class="'title '+topic.color ">{{topic.subheader}}</p>
              <p :class="'sub-title2 '+topic.color " >{{topic.subheader2}}</p>
              <button v-if="topic.button_text" :class="topic.color === 'black' ? 'red-button' : 'white-button'" >{{topic.button_text}}</button>

            </template>
          </div>
          <div v-else class="ufak-box">
            <div class="bg" :style="'background-image: url('+topic.image_url_new_today+')'"></div>
            <div class="title-area">
              <p class="sub-title">{{topic.subheader}}</p>
              <p class="title">{{topic.header}}</p>
            </div>
          </div>


          <div class="hiddendiv"></div>
          <div class="hiddendiv"></div>
          <div class="hiddendiv"></div>

          <h4 v-if="topic.size_type === 'A1' || topic.size_type === 'B2'">Ölçü: 918 x 710 px (Blog Listeleme Sayfaları)</h4>
          <h4 v-else>Ölçü: 578 x 710 px</h4>
          <div class="hiddendiv10"></div>

          <label style="width: 120px!important; cursor: pointer" for="files_application_list" >
            <span v-if="!image_url_list_uploading" class="super-button-small"> Görsel Yükle</span>
            <span v-else>Bekleyin...</span>
          </label>

          <input id="files_application_list" style="display:none;" type="file" @change="onFileChange_list">

          <div v-if="image_url_list_uploading">Dosya yükleniyor . . .</div>
          <div v-if="image_url_list_uploading" class="loader"></div>

          <div style="margin-top: 20px; display: flex">
            <span class="nounderlinehover">
              <div v-if="topic.style_type !== 'list'" :class="['AB_card'+topic.size_type, 'AB_generalCard', 'AB_card_'+topic.style_type, topic.color]" :style="' background-size:cover; background-image: url('+topic.image_url_list+');'">
                <div v-if="topic.style_type === 'bottom_title' || topic.style_type === 'long_title'" class="bottom_text_placeholder">
                  <p class="subheader">{{topic.subheader}}</p>
                  <p class="title" v-html="topic.header" :style="'backgroundColor:'+computedBG"></p>
                  <p class="subheader2">{{topic.subheader2}}</p>
                   <button style="margin-left: 20px!important;  bottom: 160px;!important;"  v-if="topic.button_text" :class="topic.color === 'black' ? 'red-button' : 'white-button'" >{{topic.button_text}}</button>
                </div>
                <div v-else>
                  <p class="subheader">{{topic.subheader}}</p>
                  <p class="title" v-html="topic.header"></p>
                  <p class="subheader2">{{topic.subheader2}}</p>
                   <button style="margin-left: 20px!important;  bottom: 160px;!important;"  v-if="topic.button_text" :class="topic.color === 'black' ? 'red-button' : 'white-button'" >{{topic.button_text}}</button>
                </div>
              </div>

            </span>
          </div>






<template v-if="1===3">


  <div class="hiddendiv"></div>
  <div class="hiddendiv"></div>
  <div class="hiddendiv"></div>

  <h4>Ölçü: 670 x 824 px (Mobile Web)</h4>
  <div class="hiddendiv10"></div>
  <label style="width: 120px!important; cursor: pointer" for="files_application_mobile" >
    <span v-if="!image_url_mobile_uploading" class="super-button-small"> Görsel Yükle</span>
    <span v-else>Bekleyin...</span>
  </label>

  <input id="files_application_mobile" style="display:none;" type="file" @change="onFileChange_mobile">

  <div v-if="image_url_mobile_uploading">Dosya yükleniyor . . .</div>
  <div v-if="image_url_mobile_uploading" class="loader"></div>

  <div style="margin-top: 20px; display: flex">
            <span class="nounderlinehover">
              <div v-if="topic.style_type !== 'list'" :class="['Mobil_card'+topic.size_type, 'Mobil_generalCard', 'AB_card_'+topic.style_type, topic.color]" :style="' background-size:cover; background-image: url('+topic.image_url_mobile+');'">
                <div v-if="topic.style_type === 'bottom_title' || topic.style_type === 'long_title'" class="bottom_text_placeholder">
                  <p class="subheader">{{topic.subheader}}</p>
                  <p class="title" v-html="topic.header"></p>
                  <p class="subheader2">{{topic.subheader2}}</p>
                </div>
                <div v-else>
                  <p class="subheader">{{topic.subheader}}</p>
                  <p class="title" v-html="topic.header"></p>
                  <p class="subheader2">{{topic.subheader2}}</p>
                </div>
              </div>

            </span>
  </div>

  <div class="hiddendiv"></div>
  <div class="hiddendiv"></div>
  <h3>Sosyal Medya Ölçüleri</h3>
  <div class="hiddendiv10"></div>
  <h4>Ölçü: 1200 x 628 px</h4>
  <div class="hiddendiv10"></div>
  <label style="width: 120px!important; cursor: pointer" for="files_application_facebook" >
    <span v-if="!image_url_facebook_uploading" class="super-button-small"> Görsel Yükle</span>
    <span v-else>Bekleyin...</span>
  </label>


  <input id="files_application_facebook" style="display:none;" type="file" @change="onFileChange_facebook">

  <div v-if="image_url_facebook_uploading">Dosya yükleniyor . . .</div>
  <div v-if="image_url_facebook_uploading" class="loader"></div>

  <div style="margin-top: 20px; display: flex">
            <span class="nounderlinehover">
              <div id="my-canvas" v-if="topic.style_type !== 'list'" :class="['Facebook_generalCard', 'FACEBOOK_card_'+topic.style_type, topic.color]" :style="'  background-image: url('+srcbb64+');'">
                <div v-if="topic.style_type === 'bottom_title' || topic.style_type === 'long_title'" class="FACEBOOK_bottom_text_placeholder">
                  <p class="subheader">{{topic.subheader}}</p>
                  <p class="title" v-html="topic.header"></p>
                </div>
                <div v-else>
                  <p class="subheader">{{topic.subheader}}</p>
                  <p class="title" v-html="topic.header"></p>
                </div>
              </div>

            </span>
  </div>



  <div class="hiddendiv10"></div>

  <div class="hiddendiv"></div>
  <h4>Render Sonrası Facebook Görseli</h4>
  <div class="hiddendiv10"></div>
  <p style="font-size: 14px;">Renk veya posizyon değişikliği yapılırsa, tekrar render yapılması gerekir.</p>
  <div class="hiddendiv10"></div>

  <div class="hiddendiv10"></div>
  <button :disabled="image_url_facebook_rendered_uploading" class="super-button-small" @click="cano">RE-RENDER</button>

  <div v-if="image_url_facebook_rendered_uploading">Tekrar Render Yapılıyor . . .</div>
  <div v-if="image_url_facebook_rendered_uploading" class="loader"></div>

  <div style="margin-top: 20px; display: flex">
            <span class="nounderlinehover">
              <div v-if="topic.style_type !== 'list'">
                <img style="width: 600px" :src="topic.image_url_facebook_rendered">
              </div>
            </span>
  </div>




  <div class="hiddendiv"></div>
  <div class="hiddendiv"></div>
  <div class="hiddendiv10"></div>
  <h4>Instagram Ölçü: 1080 x 1080 px</h4>
  <div class="hiddendiv10"></div>
  <label style="width: 120px!important; cursor: pointer" for="files_application_instagram" >
    <span v-if="!image_url_instagram_uploading" class="super-button-small"> Görsel Yükle</span>
    <span v-else>Bekleyin...</span>
  </label>



  <div class="hiddendiv"></div>
  <button :disabled="image_url_instagram_rendering" class="super-button-small" @click="cano_ig">RENDER EDİLMİŞ GÖRSELİ İNDİR</button>
  <p v-if="image_url_instagram_rendering">Bekleyin...</p>
  <div class="hiddendiv10"></div>
  <input id="files_application_instagram" style="display:none;" type="file" @change="onFileChange_instagram">

  <div v-if="image_url_instagram_uploading">Dosya yükleniyor . . .</div>
  <div v-if="image_url_instagram_uploading" class="loader"></div>

  <div style="margin-top: 20px; display: flex">
            <span class="nounderlinehover">
              <div id="my-canvas-ig" v-if="topic.style_type !== 'list'" :class="['IG_generalCard', 'IG_card_'+topic.style_type, topic.color]" :style="'  background-image: url('+srcbb64_ig+');'">
                <div v-if="topic.style_type === 'bottom_title' || topic.style_type === 'long_title'" class="IG_bottom_text_placeholder">
                  <p class="subheader">{{topic.subheader}}</p>
                  <p class="title" v-html="topic.header"></p>
                </div>
                <div v-else>
                  <p class="subheader">{{topic.subheader}}</p>
                  <p class="title" v-html="topic.header"></p>
                </div>
              </div>

            </span>
  </div>
</template>



        </div>

      </div>
    </div>
  </div>
</template>

<script>
  import FooterNav from "../../../../components/shared/navFooter";

  import html2canvas from 'html2canvas';



  export default {
    name: "src-pages-body-staticContents-blog-list-v1",
    components: {FooterNav},
    data() {
      return {
        imgHeight:0,
        imgWidth:0,
        image_url_uploading:false,
        image_url_new_today_uploading:false,
        image_url_mobile_uploading:false,
        image_url_list_uploading: false,
        image_url_facebook_uploading: false,
        image_url_facebook_rendered_uploading: false,
        image_url_instagram_rendering: false,
        image_url_instagram_uploading: false,
      	pageLoaded: true,
        srcbb64:null,
        srcbb64_ig:null,
        renderedFacebookDataURLWillUpload: null,
        topic:{
          image_url:"https://gcdn.bionluk.com/site/general/blank_gig.png",
          image_url_list:"https://gcdn.bionluk.com/site/general/blank_gig.png",
          image_url_mobile:"https://gcdn.bionluk.com/site/general/blank_gig.png",
          image_url_new_today:"https://gcdn.bionluk.com/site/general/blank_gig.png",
          header:"Aramıza Yeni Katılan Seslendirmenler",
          subheader: "YENİ",
          subheader2: "",
      	  size_type: "A1",
          style_type: "up_title",
          color: "black",
          blog_id:1,
          header_bg_color:null,
        }
      };
    },

    methods: {
      changeStoryInfo() {
        this.api.content.changeStoryInfo(this.topic.blog_id, this.topic.color, this.topic.style_type)
          .then(({data}) => {
            let result = data;
            this.pageLoaded = true;
            if (result.success) {

              this.$toasted.global.infoToast({description: result.message});
            } else {
              this.$toasted.global.errorToast({description: result.message});
            }
          })
          .catch(err => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          });
      },
      getBlogImage() {
        this.api.content.getBlogImage(this.actualPath)
          .then(({data}) => {
            let result = data;
            this.pageLoaded = true;
            if (result.success) {
              this.topic = result.data.topic;
              this.getBase64ImageFromUrl(result.data.topic.image_url_facebook)
              .then(result => this.srcbb64 = result)
              .catch(err => console.error(err));

              this.getBase64ImageFromUrl(result.data.topic.image_url_instagram)
              .then(result => this.srcbb64_ig = result)
              .catch(err => console.error(err));

            } else {
              this.$toasted.global.errorToast({description: result.message});
            }
          })
          .catch(err => {
            console.log(err);
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          });
      },

     async  getBase64ImageFromUrl(imageUrl) {
        let res = await fetch(imageUrl);
        let blob = await res.blob();

        return new Promise((resolve, reject) => {
          let reader  = new FileReader();
          reader.addEventListener("load", function () {
            resolve(reader.result);
          }, false);

          reader.onerror = () => {
            return reject(this);
          };
          reader.readAsDataURL(blob);
        })
    },


      onFileChange(e) {

        this.image_url_uploading = true;
        let files = e.target.files || e.dataTransfer.files;
        if (!files.length)
          return;

        let timeStamp = +new Date();


        let _URL = window.URL || window.webkitURL;
        let img;
        img = new Image();
        img.onload = function () {
          if(this.width !== 1920 || this.height !== 780){
            alert("UYARI: Yüklemek istediğin görsel boyutu: "+this.width+" x "+this.height+" px. Lütfen 1820 x 780 px ölçülerinde bir görsel yükle. Bu dosya yüklenmeye devam edecek ama görseli bu ölçülerde değiştirerek tekrar yükle.");
            this.image_url_mobile_uploading = false;
            return false;
          }

        };
        img.src = _URL.createObjectURL(files[0]);



        this.uploadFileToGoogle(files[0], this.Constants.UPLOAD_TYPES.STORY, this.topic.blog_id, timeStamp)
          .then(result => {

            this.api.content.updateStoryImage(result.uploadName,result.externalID,this.topic.blog_id,"image_url")
              .then(({data}) => {
                let result = data;
                if (result.success) {
                  this.topic.image_url = result.data.uploadName;
                  e.target.value = '';
                  this.image_url_uploading = false;

                } else {
                  this.image_url_uploading = false;
                  this.$toasted.global.errorToast({description: result.message});

                }
              })
              .catch(err => {
                this.image_url_uploading = false;
                this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
              });




          })
          .catch(err => {
            this.image_url_uploading = false;
            this.$store.state.percentCompleted.blog[timeStamp] = 0;
            this.$toasted.global.infoToast({description: err ? err.message : this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
            e.target.value = '';
          });
      },

      onFileChange_list(e) {

        this.image_url_list_uploading = true;
        let files = e.target.files || e.dataTransfer.files;
        if (!files.length)
          return;

        let timeStamp = +new Date();



        let _URL = window.URL || window.webkitURL;
        let img;
        img = new Image();
        img.onload = function () {
          if(this.width !== 918 || this.height !== 710){
            alert("UYARI: Yüklemek istediğin görsel boyutu: "+this.width+" x "+this.height+" px. Lütfen 918 x 710 px ölçülerinde bir görsel yükle. Bu dosya yüklenmeye devam edecek ama görseli bu ölçülerde değiştirerek tekrar yükle.");
            this.image_url_mobile_uploading = false;
            return false;
          }

        };
        img.src = _URL.createObjectURL(files[0]);



        this.uploadFileToGoogle(files[0], this.Constants.UPLOAD_TYPES.STORY, this.topic.blog_id, timeStamp)
          .then(result => {

            this.api.content.updateStoryImage(result.uploadName,result.externalID,this.topic.blog_id,"image_url_list")
              .then(({data}) => {
                let result = data;
                if (result.success) {
                  this.topic.image_url_list = result.data.uploadName;
                  e.target.value = '';
                  this.image_url_list_uploading = false;

                } else {
                  this.image_url_list_uploading = false;
                  this.$toasted.global.errorToast({description: result.message});

                }
              })
              .catch(err => {
                this.image_url_list_uploading = false;
                this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
              });



          })
          .catch(err => {
            this.image_url_list_uploading = false;
            this.$store.state.percentCompleted.blog[timeStamp] = 0;
            this.$toasted.global.infoToast({description: err ? err.message : this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
            e.target.value = '';
          });
      },

      getImageSize(){


      },

      onFileChange_new_today(e) {

        this.image_url_new_today_uploading = true;
        let files = e.target.files || e.dataTransfer.files;
        if (!files.length)
          return;

        let timeStamp = +new Date();


        let _URL = window.URL || window.webkitURL;
        let img;
        img = new Image();

        img.src = _URL.createObjectURL(files[0]);

        console.log(files[0]);
        this.uploadFileToGoogle(files[0], this.Constants.UPLOAD_TYPES.STORY, this.topic.blog_id, timeStamp)
          .then(result => {

            this.api.content.updateStoryImage(result.uploadName,result.externalID,this.topic.blog_id,"image_url_new_today")
              .then(({data}) => {
                let result = data;
                if (result.success) {
                  this.topic.image_url_new_today = result.data.uploadName;
                  e.target.value = '';
                  this.image_url_new_today_uploading = false;

                } else {
                  this.image_url_new_today_uploading = false;
                  this.$toasted.global.errorToast({description: result.message});

                }
              })
              .catch(err => {
                this.image_url_new_today_uploading = false;
                this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
              });



          })
          .catch(err => {
            this.image_url_new_today_uploading = false;
            this.$store.state.percentCompleted.blog[timeStamp] = 0;
            this.$toasted.global.infoToast({description: err ? err.message : this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
            e.target.value = '';
          });

      },

      onFileChange_mobile(e) {

        this.image_url_mobile_uploading = true;
        let files = e.target.files || e.dataTransfer.files;
        if (!files.length)
          return;

        let timeStamp = +new Date();


        let _URL = window.URL || window.webkitURL;
        let img;
        img = new Image();
        img.onload = function () {

          if(this.width !== 670 || this.height !== 824){
            alert("UYARI: Yüklemek istediğin görsel boyutu: "+this.width+" x "+this.height+" px. Lütfen 670 x 824 px ölçülerinde bir görsel yükle. Bu dosya yüklenmeye devam edecek ama görseli bu ölçülerde değiştirerek tekrar yükle.");
            this.image_url_mobile_uploading = false;
            return false;
          }
        };

        img.src = _URL.createObjectURL(files[0]);


        console.log(files[0]);
        this.uploadFileToGoogle(files[0], this.Constants.UPLOAD_TYPES.STORY, this.topic.blog_id, timeStamp)
          .then(result => {

            this.api.content.updateStoryImage(result.uploadName,result.externalID,this.topic.blog_id,"image_url_mobile")
              .then(({data}) => {
                let result = data;
                if (result.success) {
                  this.topic.image_url_mobile = result.data.uploadName;
                  e.target.value = '';
                  this.image_url_mobile_uploading = false;

                } else {
                  this.image_url_mobile_uploading = false;
                  this.$toasted.global.errorToast({description: result.message});

                }
              })
              .catch(err => {
                this.image_url_mobile_uploading = false;
                this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
              });



          })
          .catch(err => {
            this.image_url_mobile_uploading = false;
            this.$store.state.percentCompleted.blog[timeStamp] = 0;
            this.$toasted.global.infoToast({description: err ? err.message : this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
            e.target.value = '';
          });

      },



      onFileChange_instagram(e) {

        this.image_url_instagram_uploading = true;
        let files = e.target.files || e.dataTransfer.files;
        if (!files.length)
          return;

        let timeStamp = +new Date();


        let _URL = window.URL || window.webkitURL;
        let img;
        img = new Image();
        img.onload = function () {
          console.log(this.width);
          console.log(this.height);
          if(this.width !== 1080 || this.height !== 1080){
            alert("UYARI: Yüklemek istediğin görsel boyutu: "+this.width+" x "+this.height+" px. Lütfen 1080 x 1080 px ölçülerinde bir görsel yükle. Bu dosya yüklenmeye devam edecek ama görseli bu ölçülerde değiştirerek tekrar yükle.");
            this.image_url_instagram_uploading = false;
            return false;
          }
        };

        img.src = _URL.createObjectURL(files[0]);



        this.uploadFileToGoogle(files[0], this.Constants.UPLOAD_TYPES.STORY, this.topic.blog_id, timeStamp)
        .then(result => {

          this.api.content.updateStoryImage(result.uploadName,result.externalID,this.topic.blog_id,"image_url_instagram")
          .then(({data}) => {
            let result = data;
            if (result.success) {
              this.topic.image_url_instagram = result.data.uploadName;
              e.target.value = '';
              this.image_url_instagram_uploading = false;

              this.getBase64ImageFromUrl(result.data.uploadName)
              .then(result => {

                this.srcbb64_ig = result;


              })
              .catch(err => console.error(err));



            } else {
              this.image_url_instagram_uploading = false;
              this.$toasted.global.errorToast({description: result.message});

            }
          })
          .catch(err => {
            this.image_url_instagram_uploading = false;
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          });



        })
        .catch(err => {
          this.image_url_instagram_uploading = false;
          this.$store.state.percentCompleted.blog[timeStamp] = 0;
          this.$toasted.global.infoToast({description: err ? err.message : this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          e.target.value = '';
        });

      },

      onFileChange_facebook(e) {

        this.image_url_facebook_uploading = true;
        let files = e.target.files || e.dataTransfer.files;
        if (!files.length)
          return;

        let timeStamp = +new Date();


        let _URL = window.URL || window.webkitURL;
        let img;
        img = new Image();
        img.onload = function () {
          console.log(this.width);
          console.log(this.height);
          if(this.width !== 1200 || this.height !== 628){
            alert("UYARI: Yüklemek istediğin görsel boyutu: "+this.width+" x "+this.height+" px. Lütfen 1200 x 628 px ölçülerinde bir görsel yükle. Bu dosya yüklenmeye devam edecek ama görseli bu ölçülerde değiştirerek tekrar yükle.");
            this.image_url_facebook_uploading = false;
            return false;
          }
        };

        img.src = _URL.createObjectURL(files[0]);


        console.log(files[0]);
        this.uploadFileToGoogle(files[0], this.Constants.UPLOAD_TYPES.STORY, this.topic.blog_id, timeStamp)
        .then(result => {

          this.api.content.updateStoryImage(result.uploadName,result.externalID,this.topic.blog_id,"image_url_facebook")
          .then(({data}) => {
            let result = data;
            if (result.success) {
              this.topic.image_url_facebook = result.data.uploadName;
              e.target.value = '';
              this.image_url_facebook_uploading = false;

              this.getBase64ImageFromUrl(result.data.uploadName)
              .then(result => {
                this.srcbb64 = result;
                this.cano();

              })
              .catch(err => console.error(err));



            } else {
              this.image_url_facebook_uploading = false;
              this.$toasted.global.errorToast({description: result.message});

            }
          })
          .catch(err => {
            this.image_url_facebook_uploading = false;
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          });



        })
        .catch(err => {
          this.image_url_facebook_uploading = false;
          this.$store.state.percentCompleted.blog[timeStamp] = 0;
          this.$toasted.global.infoToast({description: err ? err.message : this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          e.target.value = '';
        });

      },



      cano_ig(){

        this.image_url_instagram_rendering = true;
        let div = document.getElementById("my-canvas-ig");




        let f_dataURLtoFile = this.dataURLtoFile;

        let blog_id = this.topic.blog_id;

        function fcbb(f_dataURLtoFile,blog_id) {
          html2canvas(div, {
            width: 1080,
            height: 1080,
          }).then(function(canvas) {

            let img   = canvas.toDataURL("image/png");

            let file = f_dataURLtoFile(
                    img, "dummyfilename.png"
            );

            let objurl = URL.createObjectURL(file);

            let link = document.createElement("a");

            link.download = "instagram_"+blog_id+".png";

            link.href = objurl;

            link.click();

          });
        }


        fcbb(f_dataURLtoFile,blog_id);

        this.image_url_instagram_rendering = false;



      },


      cano(){

        this.image_url_facebook_rendered_uploading = true;
        let div = document.getElementById("my-canvas");

        let kgkg = this.uploadFacebookImageRendered;

        function fcbb(kgkg) {
          html2canvas(div, {
            width: 1200,
            height: 628,
          }).then(function(canvas) {
            kgkg(canvas.toDataURL())
          });
        }


        fcbb(kgkg);

      },


      uploadFacebookImageRendered(dataURL){



        let file = this.dataURLtoFile(
                dataURL, "dummyfilename.png"
        );

        let timeStamp = +new Date();

        this.uploadFileToGoogle(file, this.Constants.UPLOAD_TYPES.STORY, this.topic.blog_id, timeStamp)
        .then(result => {

          this.api.content.updateStoryImage(result.uploadName,result.externalID,this.topic.blog_id,"image_url_facebook_rendered")
          .then(({data}) => {
            let result = data;
            if (result.success) {
              this.topic.image_url_facebook_rendered = result.data.uploadName;
              this.image_url_facebook_rendered_uploading = false;

            } else {
              this.image_url_facebook_rendered_uploading = false;
              this.$toasted.global.errorToast({description: result.message});

            }
          })
          .catch(err => {
            this.image_url_facebook_rendered_uploading = false;
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          });



        })
        .catch(err => {
          this.image_url_facebook_rendered_uploading = false;
          this.$store.state.percentCompleted.blog[timeStamp] = 0;
          this.$toasted.global.infoToast({description: err ? err.message : this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
        });
      }

    },

    computed: {
      actualPath() {
        return this.$store.state.routerParams[0];
      },
      computedBG(){
        let hex = this.topic.header_bg_color;
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ?
          'rgba('+ parseInt(result[1], 16) + ','+ parseInt(result[2], 16) + ',' +  parseInt(result[3], 16) + ',0.5)'
          : null;

      }

    },



    mounted() {
      this.pageLoaded = false;
      this.getBlogImage();
    }
  }
</script>

<style scoped lang="scss">


  .red-button {
    position: absolute;
    bottom: 60px;
    width: 190px;
    height: 50px;
    padding-bottom: 3px;
    border-radius: 2px;
    background-color: #fd4056;
    border-color: #fd4056;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 600;
    margin-left: 40px;
    color: #ffffff;

    &:hover {
      background-color: #fd4056 !important;
    }
  }

  .white-button{
    position: absolute;
    bottom: 60px;
    width: 190px;
    height: 50px;
    padding-bottom: 3px;
    border-radius: 2px;
    background-color: transparent;
    border-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 600;
    margin-left: 40px;
    color: #ffffff;
    &:hover{
      background-color: transparent!important;
    }
  }



  .ufak-box{
    cursor: pointer;
    width: 241px;
    height: 270px;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(45, 54, 64, 0.05);
    border: solid 1px #eaedf2;
    background-color: #ffffff;

    &:hover {
      box-shadow: 0 15px 20px 0 rgba(45, 54, 64,.1);
      text-decoration: none!important;
    }

    .title-area{
      height: 96px; margin-left: 20px; margin-top: 15px;
    }

    .bg{
      border-top-right-radius: 10px;border-top-left-radius: 10px;width:241px; height:174px; background-position: center; background-size: contain; background-repeat: no-repeat;
    }

    .sub-title{
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #8b95a1;
    }

    .title{
      margin-top: 3px;
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #00a575;
    }
  }

  .freelancer-box{
    position: relative;
    cursor: pointer;
    width: 528px;
    height: 300px;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(45, 54, 64, 0.05);
    background-size:cover;

  &:hover {
     box-shadow: 0 15px 20px 0 rgba(40,60,98,.1);
     text-decoration: none!important;
   }

  .title{
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
     margin-top: 5px;
    padding-left: 40px;
    &:hover{
      text-decoration: none;
    }
  }

  .sub-title{
    font-size: 23px;
    font-weight: normal;
    padding-left: 40px;
    letter-spacing: .34px;
    text-align: left;
    line-height: 1.25;
    padding-top: 40px;
    &:hover{
      text-decoration: none;
    }
  }


  .sub-title2{

    font-weight: normal;
    padding-left: 40px;

    text-align: left;

     margin-top: 4px;

    font-size: 18px;
    font-stretch: normal;
    font-style: italic;
    line-height: normal;
    letter-spacing: normal;
    &:hover{
      text-decoration: none;
    }

  }
  }

  .freelancer-box_long_title{
     position: relative;
     cursor: pointer;
     width: 528px;
     height: 300px;
     border-radius: 10px;
     box-shadow: 0 0 10px 0 rgba(45, 54, 64, 0.05);
     background-size:cover;

     &:hover {
       box-shadow: 0 15px 20px 0 rgba(40,60,98,.1);
       text-decoration: none!important;
     }

     .title{
       font-size: 30px;
       font-weight: bold;
       font-stretch: normal;
       font-style: normal;
       line-height: 1.33;
       letter-spacing: normal;
       margin-top: 5px;
       padding-left: 20px;
       &:hover{
         text-decoration: none;
       }
     }

     .sub-title{

       padding: 6px 10px 8px;
       border-radius: 4px;


       margin-left: 12px;
       margin-right: 12px;

       bottom:14px;


       text-align: left;
       position: absolute;

       font-size: 24px;
       font-weight: bold;
       font-stretch: normal;
       font-style: normal;
       line-height: 1.30;
       letter-spacing: normal;

       &:hover{
         text-decoration: none;
       }
     }


     .sub-title2{

       font-weight: normal;
       padding-left: 40px;

       text-align: left;

       margin-top: 4px;

       font-size: 18px;
       font-stretch: normal;
       font-style: italic;
       line-height: normal;
       letter-spacing: normal;
       &:hover{
         text-decoration: none;
       }

     }
   }

  .blogContent{
    text-align: left;
    position: relative;

    font-size: 18px;


    letter-spacing: -0.32px;
    line-height: 24px;





    background-color: rgb(255, 255, 255);
    box-shadow: 0 0px 0px 0 rgba(40, 60, 98, 0.1);
    padding: 20px;
  }

  .blogContentYorum{
    background-color: #ffffff;
    border: solid 1px #eaedf2; border-radius:5px;line-height: 1.3; color: #707070; font-size: 14px;width: 830px; margin:0 auto;background: #FFFFFF;
  }



  .blogContent h2 {
    font-weight: 600;
    font-size: 28px !important;
    color: #2d3640 !important;
    margin-bottom: 5px;
    text-align: left!important;
  }

  .blogContent h2 strong {
    font-weight: 600;
    font-size: 28px !important;
    color: #2d3640 !important;
    margin-bottom: 5px;
    text-align: left!important;
  }




  .generalCard{
    text-align: left;
    position: relative;

    border-top-left-radius: 16px;
    border-top-right-radius: 16px;



    background-color: rgb(255, 255, 255);
    box-shadow: 0 0px 20px 0 rgba(40, 60, 98, 0.1);
  }


  .generalCard:hover{
    text-decoration: none!important;
  }




  .cardA1{
    width: 960px; height: 390px; background-position: center;
  }






  .freelancer-list{
    margin-right: 14px
  }

  .freelancer-list-div{
    display: flex; height: 100px;
  }

  .freelancer-list-div-div_user{
    align-self: center;
    width: 132px;

  }

  .freelancer-list-div-div_gig{
    align-self: center;
    width: 187px;

  }

  .freelancer-list-avatar{
    height:80px;  border:1px solid #dde3eb;border-radius: 4px;
  }

  .freelancer-list-avatar_s{
    height:80px;  border:1px solid #dde3eb;border-radius: 12px;
  }


  .freelancer-list-second-flex{
    margin-left: 32px; height: 80px; align-self: center; width: 500px;
  }

  .freelancer-list-username{padding-top: 5px;
    line-height:36px;font-size: 24px; font-weight: 400; color: rgb(36, 36, 36);
  }

  .freelancer-list-user-title{
    line-height:19px; font-size: 19px; font-style: italic; color: rgb(139, 149, 161);
  }

  .freelancer-list-border{
    margin-left:177px;border-bottom: 1px solid #e1e1e1; margin-right: 32px;
  }

  .freelancer-list-border_s{
    margin-left:110px;border-bottom: 1px solid #e1e1e1; margin-right: 32px;
  }


  .freelancer-list-user-title_s{
    line-height:19px; font-size: 15px; font-style: italic; color: rgb(139, 149, 161);
  }

  .card_bold .title{

    font-size: 48px; width: 250px;

    bottom: 80px;
    left: 30px;
    position: absolute;
    z-index: 1;
    font-weight: 800;
    shadow: 0 15px 20px 0 rgba(40, 60, 98, 0.1);


  }


  .card_bold .subheader{

    font-weight:400; opacity: 0.6; font-size:20px;line-height: 1.56;
    position: absolute;
    bottom: 48px;
    left: 30px;


  }


  .card_up_title .title{


    font-size: 32px;
    font-weight: 600;
    padding-left: 20px;


    letter-spacing: 0.34px;
    text-align: left;
    line-height: 40px;


  }


  .card_up_title .subheader{


    opacity: 0.7;
    font-size: 15px;
    letter-spacing: -0.24px;



    padding: 18px 20px 6px 20px;

    font-size: 20px; opacity:0.75;font-weight: 500;
    line-height: 24px;

  }



  .card_up_title .subheader2{


    position: absolute;
    bottom: 20px;
    left: 20px;
    font-size: 17px;
    opacity:0.7;

    letter-spacing: -0.32px;
    line-height: 24px;
    margin-bottom: 10px;

  }



  .card_list .title{



    font-size: 32px;
    font-weight: 600;
    padding-left: 20px;


    letter-spacing: 0.34px;
    text-align: left;
    line-height: 40px;

  }


  .card_list .subheader{

    opacity: 0.7;
    font-size: 15px;
    letter-spacing: -0.24px;




    padding: 18px 20px 6px 20px;

    font-size: 20px; opacity:0.75;font-weight: 500;
    line-height: 24px;
  }



  .card_list .subheader2{

    position: absolute;
    bottom: 20px;
    left: 20px;
    font-size: 17px;
    opacity:0.7;

    letter-spacing: -0.32px;
    line-height: 24px;
    margin-bottom: 10px;
  }



  .bottom_text_placeholder {

    position: absolute;
    bottom: 16px;
    left: 12px;
    right: 20px;
  }


  .card_bottom_title .subheader{


    z-index: 1;

    font-size: 20px; opacity:0.75;font-weight: 500;
    line-height: 32px;

  }


  .card_bottom_title .title{

    font-size: 32px;
    font-weight: 600;
    line-height: 50px;

  }



  .card_long_title .title{

    bottom:2px;

    text-align: left;
    position: absolute;

    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.30;
    letter-spacing: normal;


  }

  .card_bottom_title .subheader2{

    padding-top: 16px;
    font-size: 18px;
    z-index: 1;
    letter-spacing: -0.32px;
    line-height: 21px;
    opacity:0.6;

  }




  .white{
    color: #fff;
  }

  .black{
    color: #242424;
  }













  .AB_generalCard{
    text-align: left;
    position: relative;
    cursor: pointer;
    border-radius: 16px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0 15px 20px 0 rgba(40, 60, 98, 0.1);
  }

  .AB_generalCard:hover{

    box-shadow: 0 15px 20px 0 rgba(40, 60, 98, 0.2);
    text-decoration: none!important;
  }



  .AB_cardA1{
    width: 459px; height: 355px; background-position: center; background-size: 459px 355px;
  }

  .AB_cardA2{
    width: 289px; height: 355px;

  }

  .AB_cardB1{
    width: 289px; height: 355px; background-position: center;  background-size: 289px 355px;

  }

  .AB_cardB2{
    width: 459px; height: 355px; background-position: center; background-size: 459px 355px;

  }

  .AB_card_bold .title{

    font-size: 32px;
    bottom: 56px;
    left: 30px;
    position: absolute;
    z-index: 1;
    font-weight: 800;
    shadow: 0 15px 20px 0 rgba(40, 60, 98, 0.1);
    width: 100%;
    word-break: break-all;
    text-wrap: normal;


  }

  .AB_card_bold .subheader{

    font-weight:400; opacity: 0.6; font-size:17px;line-height: 1.56;
    position: absolute;
    bottom: 32px;
    left: 30px;




  }

  .AB_card_up_title .title{
    font-size: 23px;
    font-weight: 600;
    padding-left: 20px;


    letter-spacing: 0.34px;
    text-align: left;
    line-height: 30px;


  }

  .AB_card_up_title .subheader{



    opacity: 0.7;
    font-size: 15px;
    letter-spacing: -0.24px;




    padding: 18px 20px 6px 20px;

    font-size: 15px; opacity:0.75;font-weight: 500;
    line-height: 18px;

  }

  .AB_card_up_title .subheader2{


    position: absolute;
    bottom: 20px;
    left: 20px;
    font-size: 14px;
    opacity:0.7;

    letter-spacing: -0.32px;
    line-height: 19px;

  }

  .AB_card_list .title{


    font-size: 24px;
    font-weight: 600;
    padding-left: 20px;


    letter-spacing: 0.34px;
    text-align: left;
    line-height: 30px;


  }

  .AB_card_list .subheader{



    opacity: 0.7;
    font-size: 15px;
    letter-spacing: -0.24px;



    padding: 18px 20px 6px 20px;

    font-size: 15px; opacity:0.75;font-weight: 500;
    line-height: 18px;

  }

  .AB_card_list .subheader2{


    position: absolute;
    bottom: 20px;
    left: 20px;
    font-size: 14px;
    opacity:0.7;

    letter-spacing: -0.32px;
    line-height: 19px;

  }

  .AB_bottom_text_placeholder {

    position: absolute;
    bottom: 16px;
    left: 20px;
    right: 20px;
  }

  .AB_card_bottom_title .subheader{


    z-index: 1;

    font-size: 16px; opacity:0.75;font-weight: 500;
    line-height: 19px;

  }
  .AB_card_bottom_title .title{

    font-size: 23px;
    font-weight: 600;
    line-height: 29px;

  }

  .AB_card_long_title .title{

    padding: 6px 10px 8px;
    border-radius: 8px;

    bottom:4px;

    text-align: left;
    position: absolute;

    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.32;
    letter-spacing: normal;


  }



  .AB_card_bottom_title .subheader2{

    padding-top: 16px;
    font-size: 15px;
    z-index: 1;
    letter-spacing: -0.32px;
    line-height: 21px;
    opacity:0.6;

  }

  .Mobil_generalCard{
    text-align: left;
    position: relative;
    cursor: pointer;
    border-radius: 16px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0 15px 20px 0 rgba(40, 60, 98, 0.1);
  }

  .Mobil_generalCard:hover{

    box-shadow: 0 15px 20px 0 rgba(40, 60, 98, 0.2);
    text-decoration: none!important;
  }





  .IG_generalCard{
    width: 1080px; height: 1080px; background-position: center; background-size:  1080px 1080px;
    box-shadow: 0 15px 20px 0 rgba(40, 60, 98, 0.1);
    text-align: left;
    position: relative;
  }

  .IG_generalCard:hover{


    text-decoration: none!important;
  }





  .IG_card_bold .title{

    font-size: 84px;
    bottom: 200px;
    left: 80px;
    position: absolute;
    z-index: 1;
    font-weight: 800;
    shadow: 0 30px 40px 0 rgba(40, 60, 98, 0.1);
    width: 100%;
    word-break: break-all;
    text-wrap: normal;


  }


  .IG_card_bold .subheader{

    font-weight:400; opacity: 0.75; font-size:54px;line-height: 1.86;
    position: absolute;
    bottom: 100px;
    left: 80px;




  }


  .IG_card_up_title .title{


    font-size: 76px;
    font-weight: 600;
    padding-left: 60px;


    letter-spacing: 0.68px;
    text-align: left;
    line-height: 70px;


  }


  .IG_card_up_title .subheader{



    opacity: 0.75;
    font-size: 48px;
    letter-spacing: -0.58px;



    padding: 48px 60px 12px 60px;

    font-weight: 500;
    line-height: 44px;

  }




  .IG_card_list .title{


    font-size: 24px;
    font-weight: 600;
    padding-left: 20px;


    letter-spacing: 0.34px;
    text-align: left;
    line-height: 30px;


  }


  .IG_card_list .subheader{



    opacity: 0.7;
    font-size: 15px;
    letter-spacing: -0.24px;




    padding: 18px 20px 6px 20px;

    font-size: 15px; opacity:0.75;font-weight: 500;
    line-height: 18px;

  }



  .IG_card_list .subheader2{


    position: absolute;
    bottom: 20px;
    left: 20px;
    font-size: 14px;
    opacity:0.7;

    letter-spacing: -0.32px;
    line-height: 19px;

  }




  .IG_bottom_text_placeholder {

    position: absolute;
    bottom: 72px;
    left: 60px;
    right: 60px;
  }


  .IG_card_bottom_title .subheader{


    z-index: 1;
    font-size: 48px; opacity:0.75;font-weight: 500;
    line-height: 72px;

  }


  .IG_card_bottom_title .title{

    font-size: 76px;
    font-weight: 600;
    line-height: 72px;

  }




  .IG_card_bottom_title .subheader2{

    padding-top: 32px;
    font-size: 30px;
    z-index: 1;
    letter-spacing: -0.64px;
    line-height: 42px;
    opacity:0.6;

  }








  .Facebook_generalCard{
    width: 1200px; height: 628px; background-position: center; background-size:  1200px 628px;
    box-shadow: 0 15px 20px 0 rgba(40, 60, 98, 0.1);
    text-align: left;
    position: relative;
  }

  .Facebook_generalCard:hover{


    text-decoration: none!important;
  }








  .FACEBOOK_card_bold .title{

    font-size: 64px;
    bottom: 112px;
    left: 60px;
    position: absolute;
    z-index: 1;
    font-weight: 800;
    shadow: 0 30px 40px 0 rgba(40, 60, 98, 0.1);
    width: 100%;
    word-break: break-all;
    text-wrap: normal;


  }


  .FACEBOOK_card_bold .subheader{

    font-weight:400; opacity: 0.6; font-size:34px;line-height: 1.56;
    position: absolute;
    bottom: 64px;
    left: 60px;




  }


  .FACEBOOK_card_up_title .title{


    font-size: 52px;
    font-weight: 600;
    padding-left: 40px;


    letter-spacing: 0.68px;
    text-align: left;
    line-height: 60px;


  }


  .FACEBOOK_card_up_title .subheader{



    opacity: 0.75;
    font-size: 38px;
    letter-spacing: -0.48px;



    padding: 36px 40px 12px 40px;

  font-weight: 500;
    line-height: 36px;

  }




  .FACEBOOK_card_list .title{


    font-size: 24px;
    font-weight: 600;
    padding-left: 20px;


    letter-spacing: 0.34px;
    text-align: left;
    line-height: 30px;


  }


  .FACEBOOK_card_list .subheader{



    opacity: 0.7;
    font-size: 15px;
    letter-spacing: -0.24px;




    padding: 18px 20px 6px 20px;

    font-size: 15px; opacity:0.75;font-weight: 500;
    line-height: 18px;

  }



  .FACEBOOK_card_list .subheader2{


    position: absolute;
    bottom: 20px;
    left: 20px;
    font-size: 14px;
    opacity:0.7;

    letter-spacing: -0.32px;
    line-height: 19px;

  }




  .FACEBOOK_bottom_text_placeholder {

    position: absolute;
    bottom: 32px;
    left: 40px;
    right: 40px;
  }


  .FACEBOOK_card_bottom_title .subheader{


    z-index: 1;
    font-size: 32px; opacity:0.75;font-weight: 500;
    line-height: 38px;

  }


  .FACEBOOK_card_bottom_title .title{

    font-size: 46px;
    font-weight: 600;
    line-height: 58px;

  }




  .FACEBOOK_card_bottom_title .subheader2{

    padding-top: 32px;
    font-size: 30px;
    z-index: 1;
    letter-spacing: -0.64px;
    line-height: 42px;
    opacity:0.6;

  }










  .Mobil_cardA1{
    width: 335px; height: 412px; background-position: center; background-size: 459px 355px;
  }


  .Mobil_cardA2{
    width: 335px; height: 412px;

  }




  .Mobil_cardB1{
    width: 335px; height: 412px; background-position: center;  background-size: 289px 355px;

  }

  .Mobil_cardB2{
    width: 335px; height: 412px; background-position: center; background-size: 459px 355px;


  }






  .Mobil_card_bold .title{

    font-size: 32px;
    bottom: 56px;
    left: 30px;
    position: absolute;
    z-index: 1;
    font-weight: 800;
    shadow: 0 15px 20px 0 rgba(40, 60, 98, 0.1);
    width: 100%;
    word-break: break-all;
    text-wrap: normal;


  }


  .Mobil_card_bold .subheader{

    font-weight:400; opacity: 0.6; font-size:17px;line-height: 1.56;
    position: absolute;
    bottom: 32px;
    left: 30px;




  }


  .Mobil_card_up_title .title{


    font-size: 23px;
    font-weight: 600;
    padding-left: 20px;


    letter-spacing: 0.34px;
    text-align: left;
    line-height: 30px;


  }


  .Mobil_card_up_title .subheader{



    opacity: 0.7;
    font-size: 15px;
    letter-spacing: -0.24px;




    padding: 18px 20px 6px 20px;

    font-size: 15px; opacity:0.75;font-weight: 500;
    line-height: 18px;

  }



  .Mobil_card_up_title .subheader2{


    position: absolute;
    bottom: 20px;
    left: 20px;
    font-size: 14px;
    opacity:0.7;

    letter-spacing: -0.32px;
    line-height: 19px;

  }



  .Mobil_card_list .title{


    font-size: 24px;
    font-weight: 600;
    padding-left: 20px;


    letter-spacing: 0.34px;
    text-align: left;
    line-height: 30px;


  }


  .Mobil_card_list .subheader{



    opacity: 0.7;
    font-size: 15px;
    letter-spacing: -0.24px;




    padding: 18px 20px 6px 20px;

    font-size: 15px; opacity:0.75;font-weight: 500;
    line-height: 18px;

  }



  .Mobil_card_list .subheader2{


    position: absolute;
    bottom: 20px;
    left: 20px;
    font-size: 14px;
    opacity:0.7;

    letter-spacing: -0.32px;
    line-height: 19px;

  }




  .Mobil_bottom_text_placeholder {

    position: absolute;
    bottom: 16px;
    left: 20px;
    right: 20px;
  }


  .Mobil_card_bottom_title .subheader{


    z-index: 1;

    font-size: 16px; opacity:0.75;font-weight: 500;
    line-height: 19px;

  }


  .Mobil_card_bottom_title .title{

    font-size: 23px;
    font-weight: 600;
    line-height: 29px;

  }




  .Mobil_card_bottom_title .subheader2{

    padding-top: 16px;
    font-size: 15px;
    z-index: 1;
    letter-spacing: -0.32px;
    line-height: 21px;
    opacity:0.6;

  }




</style>
